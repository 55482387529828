import React from 'react'

import pic from '../../assets/images/4.png'
import { useHistory } from 'react-router-dom';

export default function SplashScreen() {

    const history = useHistory();

  return (
    <div className='w-full h-full overflow-auto ' >
        <div className='mx-8 my-8 flex flex-row items-center' >
            <svg xmlns="http://www.w3.org/2000/svg" width="26.974" height="21" viewBox="0 0 26.974 21">
                <g transform="translate(-32.5 -56.8)">
                    <g transform="translate(32.5 56.8)">
                        <path className="a fill-current text-primary" d="M54.479,56.806,46.664,77.8H42.027a2.715,2.715,0,0,1-2.47-1.6l-.129-.351L32.5,56.806h7.082l2.833,9.743a48.613,48.613,0,0,1,1.2,5.105h.129c.345-1.712.69-3.307,1.158-5.105l1.2-4.329H43.85l.788-3.11A2.7,2.7,0,0,1,47.311,56.8c.1,0,.191.006.289.012l.006-.012h6.873Z" transform="translate(-32.5 -56.8)"/>
                    </g><circle className="b fill-current " color='#FF4471' cx="3.024" cy="3.024" r="3.024" transform="translate(53.426 71.66)"/>
                </g>
            </svg>
            <p className='font-abeat ml-4 mt-2 text-xl' >vently</p>
        </div>
        <div className='mx-8 my-8 flex flex-col'>
            <p className=' font-heebo-regular mt-8 mb-4 text-sm'>Create An Account Or Log In To Continue</p>
            <button onClick={()=> history.push('/signup')} className='h-12 my-2 w-full text-sm text-white font-heebo-medium' style={{borderRadius:'3px', backgroundColor:'#FF4471'}} >Create Account</button>
            <button onClick={()=> history.push('/login')} className='h-12 my-2 w-full text-sm font-heebo-medium' style={{color:'#FF4471', borderRadius:'3px', border:'solid 1px #FF4471', }} >Log In</button>
        </div>
            <div className='w-full h-full' >
                <BottomLayer pic={pic} />
            </div>
        </div>
    // </div>
  );
}

function BottomLayer(props: any){
    return(
        <div className='relative w-full h-full flex bottom-0' style={{backgroundImage:`url(${props.pic})`, width:'100%', height:'80vh', backgroundRepeat:'no-repeat', backgroundSize:'cover'}} >
            <div className='w-full flex flex-col items-center px-8 pt-32 bg-black bg-opacity-75 rounded-md' >
                <p className='text-base text-white font-poppins-bold' >Events Should Be Awesome</p>
                <p className='text-sm text-white text-center font-heebo-regular mt-4'>Become part of an amazing community and change how you attend events forever</p>
            </div>
            <div className=' fixed bottom-0 pt-4 pl-8 pb-4 w-full text-white' style={{backgroundColor:'#FF4471'}} >
                <p className='text-base font-heebo-medium' >Vently is better on the App</p>
                <p className='text-sm mt-2 mr-2 font-heebo-regular' >Create and manage events all in one place. Open this on the Vently app for better experience</p>
                <div className='w-full flex flew-row mt-3' >
                    <button className='mr-1 w-24 text-xs h-12 text-white font-heebo-medium' style={{ borderRadius:'3px', border:'solid 1px #FFF', backgroundColor:'#FF4471' }} >Not Now</button>
                    <button className='h-12 ml-1 w-32 text-xs bg-white font-heebo-medium' style={{color:'#FF4471',borderRadius:'3px'}} >Switch To The App</button>
                </div>
            </div>
        </div>
    )
}