import React from 'react'
 
// import ProtectedRoute from './ProtectedRoute';
import AccessRoute from './AccessedRoute'
import { Route, Switch } from 'react-router-dom';
import HomePage from '../MobileView/HomeScreen/Home'
import Login from '../MobileView/Login-Screen/Login'
import Signup from '../MobileView/Registration-Screens/Signup'

export default function WebsiteRoute() {

    const [token, setToken] = React.useState(localStorage.getItem('token'));  

    const getToken = () => {
        const tokenString: any = localStorage.getItem('token');
        setToken(tokenString);  
    };

    React.useEffect(() => {
        getToken();
    },);

    return (
        <Switch>
            <AccessRoute 
                isAuthenticated={token} 
                exact
                path='/'
                component={HomePage}
            /> 

            <AccessRoute 
                isAuthenticated={token} 
                exact
                path='/login'
                component={Login}
            />

            <Route 
                // isAuthenticated={token}
                // isUserId={Id}
                exact
                path='/signup'
                component={Signup}
            />
            
            {/* <AccessRoute 
                isAuthenticated={token} 
                exact
                path="/verifyemail" 
                component={VerifyEmail} 
            />  */}
            
            {/* <ProtectedRoute 
                isAuthenticated={token} 
                exact
                path='/feed'
                component={Feeds}
            /> */}

        </Switch>
    )
}