import React from 'react'

import facebook from '../../assets/icons/facebook.svg'
import google from '../../assets/icons/google.svg'
import mail from '../../assets/icons/email.svg'
import lock from "../../assets/icons/lock3.svg"
import password_open from "../../assets/icons/open.svg"
import password_close from "../../assets/icons/close.svg"
import { useHistory} from 'react-router-dom'

export default function SignInScreen() {

    const [showpassword, setShowpass] = React.useState(false);
    const handleShowpassword = () => {
        setShowpass((prev) => !prev);
      };

    const history = useHistory();

    return (
        <div className='w-full h-full mb-8' >
            <div className='mx-8 my-10 flex flex-row justify-center items-center' >
                <svg xmlns="http://www.w3.org/2000/svg" width="26.974" height="21" viewBox="0 0 26.974 21">
                    <g transform="translate(-32.5 -56.8)">
                        <g transform="translate(32.5 56.8)">
                            <path className="a fill-current text-primary" d="M54.479,56.806,46.664,77.8H42.027a2.715,2.715,0,0,1-2.47-1.6l-.129-.351L32.5,56.806h7.082l2.833,9.743a48.613,48.613,0,0,1,1.2,5.105h.129c.345-1.712.69-3.307,1.158-5.105l1.2-4.329H43.85l.788-3.11A2.7,2.7,0,0,1,47.311,56.8c.1,0,.191.006.289.012l.006-.012h6.873Z" transform="translate(-32.5 -56.8)"/>
                        </g><circle className="b fill-current " color='#FF4471' cx="3.024" cy="3.024" r="3.024" transform="translate(53.426 71.66)"/>
                    </g>
                </svg>
                <p className='font-abeat ml-4 mt-2 text-xl' >vently</p>
            </div>
            <div className='w-full px-4'>
                <div className='w-full px-2'>
                    <p className='font-poppins-medium text-xl' >Welcome Back</p>
                    <p className='font-heebo-regular text-sm mt-2'>Great to see you back , log In To Continue</p>
                </div>
                <div className='w-full flex flex-row mt-4' >
                    <div className='w-full mr-2' >
                        <LoginButton pic={facebook} color={'#0963BC'} font={'#fff'}  name={'Facebook'} />
                    </div>
                    <div className='w-full ml-2'>
                        <LoginButton pic={google} color={'#fff'} font={'#5B5B5B'} border={'solid 1pt #D8D4D4'} name={'Google'} />
                    </div>
                </div>
            </div>
            <div className=' w-full flex flex-col mt-10 ' >
                <div className='w-full mt-4 flex px-4'>
                    <div className="mt-4">
                        <img src={mail} width="23" className="absolute ml-4 " alt=""/>
                    </div>
                    <input
                        name="email"
                        placeholder="example@vent.ly"
                        className=" w-full py-4 rounded pl-12 text-sm pr-12 font-heebo-regular" style={{backgroundColor:'#ECECEC91'}} />
                </div>
                <div className='relative w-full mt-4 flex px-4'>
                    <div className="mt-3">
                        <img src={lock} width="20" className="absolute ml-4 " alt=""/>
                    </div>
                    <input
                        name="password"
                        placeholder="Password"
                        type={showpassword ? "text" : "password"}
                        className=" w-full py-4 rounded pl-12 text-sm pr-12 font-heebo-regular" style={{backgroundColor:'#ECECEC91'}} />
                    <div onClick={handleShowpassword} className='flex justify-end'>
                        {!showpassword ? (
                            <div className='absolute w-14 h-12 p-3'>
                                <img
                                    src={password_open}
                                    // width="27" 
                                    className='w-full h-full'
                                    alt="" />
                            </div>
                        ) : (
                            <div className='absolute w-14 h-12 p-3'>
                                <img
                                    src={password_close}
                                    // width="27" 
                                    className='w-full h-full'
                                    alt="" />
                            </div> 
                        )}
                    </div>
                </div> 
            </div>
            <div className='w-full flex flex-row px-4 mt-4'>
                <div className=' w-full flex flex-1' />
                <p className=' font-heebo-regular text-sm text-accent mr-3' >Forgot Password?</p>
            </div>

            <div className='w-full px-4 mt-8' >
                <button className='w-full text-sm h-12 text-white font-heebo-medium' style={{ borderRadius:'3px', border:'solid 1px #FFF', backgroundColor:'#FF4471' }} >Log In</button>
            </div>
            
            <div className='w-full flex flex-row justify-center items-center mt-8'>
                <p className=' font-heebo-regular text-sm text-primary'>Don't have an account?</p>
                <p onClick={()=> history.push('/signup')} className=' font-heebo-regular text-sm text-accent ml-2'>Create One Now</p>
            </div>
            
        </div>
    )
}

function LoginButton(props: any){
    return(
        <button className="rounded w-full h-12 flex justify-center items-center flex-1" style={{backgroundColor: props.color, color: props.font, border: props.border}} >
            <img src={props.pic} className='w-5 h-5' alt=""/>
            <p className="ml-2 font-heebo-medium text-sm ">{props.name}</p>
        </button>
    )
}