import React from 'react';
import { isDesktop } from 'react-device-detect';
import { BrowserRouter as Router, Switch} from 'react-router-dom';

import Routes from './Routes/WebsiteRoute' 

function App() { 

  React.useEffect(() => {  
    if(isDesktop){ 
        document.location.reload(true);
            window.location.replace('https://web.vent.ly/')
      }

    },);

  return (
    <Router>
      <Switch>
        <Routes/>
      </Switch>
    </Router>
    
  );
}

export default App; 