import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export default function AccessedRoutes({ isAuthenticated, ...props }: any) {
    
    return !isAuthenticated ?
        (   
            <Route {...props} />  
        ):(
            <Redirect to= '/feed' />
        )
}
